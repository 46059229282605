.App {
  text-align: center;
}
.container{
  padding:20px;
    /*background:#dbf0f5*/
}
.radius-container{
    padding:0 0px 20px
}
.radius-container img{
 border-radius:5vw;
    width:100%;
    height:auto;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);

}
.lk-30{
  margin:-15px -15px -10%
 }
.lk-30 img{
    width:100%;
    height:auto;
}
.btns{
   margin-left:15px;
    margin-right:-15px
}
.btns img{
    width: calc( 25% + 45px/4  );
    height: auto;
    margin-left:-15px;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    border-radius:5% 5%  50% 50%;
    position: relative;
}
.btns a:nth-child(1) img{
    z-index:4
}
.btns a:nth-child(2) img{
    z-index:3;
   /*top:-0.5px*/
}
.btns a:nth-child(3) img{
    z-index:2
}
.btns a:nth-child(4) img{
    z-index:1
}
